import React, {createContext, useEffect, useState} from 'react';
import { CookiesProvider, useCookies } from 'react-cookie'

const DarkModeContext = createContext();

function DarkModeProvider(props) {

    const [cookies, setCookie] = useCookies(['darkmode'])
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const cookie = cookies.darkmode;

        if (cookie == undefined) {
            setCookie('darkmode', false)
        } else {
            setDarkMode(cookies.darkmode)
        }
    }, [])

    const toggleDarkMode = () => {
        const option = !darkMode;
        
        setDarkMode(option);
        setCookie('darkmode', option);
    }


    return (
        <div>
            <DarkModeContext.Provider value={{darkMode, toggleDarkMode}}>
                {props.children}
            </DarkModeContext.Provider>
        </div>
    )
}

export {DarkModeContext, DarkModeProvider}