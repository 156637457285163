import { useContext, useEffect, useState } from 'react'
import './dashboardSavings.css'

import SavingsMeter from './SavingsMeter/savingsMeter'
import { DarkModeContext } from '../../../../context/DarkModeContext';
import Policy from '../../../../api/policy';
import { Area, AreaChart, CartesianAxis, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Tooltip } from 'antd';
import { addDays, addHours, eachHourOfInterval, endOfDay, format, isToday, max, startOfDay, subDays } from 'date-fns';
import GraphValues from '../../../../api/graphValues';
import User from '../../../../api/user'
import loaderGIF from '../../../../Images/firn-embleem-animation.gif'
import Settings from '../../../../api/settings';

const DashboardSavings = ({serialNr, toggleHidden, updateMaxBattCap, updateBattInstallation}) => {
    const {darkMode} = useContext(DarkModeContext);
    const [yesterDaySavings] = useState(18);
    const [todaySavings] = useState(8);

    const [hasPolicys, setHasPolicys] = useState(false)
    const [maxBattCap, setMaxBattCap] = useState(0)

    const [Globaldate, setDate] = useState(new Date())
    const graphValuesApi = GraphValues();

    const settingsApi = Settings();
    const policyApi = Policy();
    const userApi = User()

    const [activeCharts, setActiveCharts] = useState({
        dayahead: true,
        soc: true,
        value: true,
        policypos: true,
        policyneg: true,
    })
    const [foundUnbalanceData, setFoundUnbalanceData] = useState(true)

    const [policyMaxValue, setPolicyMaxValue] = useState(0);
    const [dayAheadMaxValue, setdayAheadMaxValue] = useState(0);

    const generateHourlyTicks = () => {
        const start = startOfDay(Globaldate);
        return Array.from({ length: 24 }, (_, i) => addHours(start, i).getTime());
    };
    
    const hourlyTicks = generateHourlyTicks();

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    //collect data
    useEffect(() => {
        let interval;
        
        const getCustomChart = async() => {
            setLoading(true)
            setHasPolicys(false)
            if (serialNr) {
                const unbalanceData = await getUnbalancedata()
                if (unbalanceData == null) {
                    setFoundUnbalanceData(false)
                } else {
                    setFoundUnbalanceData(true)
                }
                //get day-ahead
                const dayaheadData = await getDayAhead()

                //get policy's
                let policysData = await getPolicys()

                //if policy data then get max batt cap else don't
                let newMaxBattCap = 0
                let battInstallation = []
                if (policysData != null && policysData.length > 0) {
                    //request json file form dataserice
                    const clientDetails = await settingsApi.getClientDetails(serialNr);

                    setHasPolicys(true)

                    for (const key in clientDetails.power_array) {
                        const item = clientDetails.power_array[key]

                        if (item.battery && item.battery.capacity_kwh) {
                            newMaxBattCap += item.battery.capacity_kwh
                            battInstallation.push(item.battery.capacity_kwh)
                        }
                    }
                    policysData = multiplyPolicys(policysData, battInstallation.length)
                }
                updateMaxBattCap(newMaxBattCap)
                updateBattInstallation(battInstallation)
                setMaxBattCap(newMaxBattCap)

                //get soc
                const socData = await getSOCdata(newMaxBattCap)


                //combine the values
                const combinedData = [].concat(
                    unbalanceData || [],
                    socData || [],
                    dayaheadData || [],
                    policysData || []
                );

                //merge data
                const mergedData = combinedData.reduce((acc, record) => {
                    const existing = acc.find(item => item.timestamp === record.timestamp);
                    
                    if (existing) {
                        // Merge the properties without changing the values
                        Object.assign(existing, record);
                    } else {
                        // Add a new entry if no match found
                        acc.push({ ...record });
                    }
                
                    return acc;
                }, []);
                
                const sortedData = mergedData.sort((a, b) => a.timestamp - b.timestamp);
                setData(sortedData)
            }
            setLoading(false)
        }

        getCustomChart();

        interval = setInterval(() => {
            setLoading(true)
            getCustomChart();
            setLoading(false)
        }, 60000 * 15);

        return () => {
            clearInterval(interval)
        };
        
    }, [serialNr, Globaldate])

    const multiplyPolicys = (data, multiplier) => {
        const newData = data.map((value) => {
            return {
                ...value,
                policypos: value.policypos * multiplier,
                policyneg: value.policyneg * multiplier
            }
        })
        return newData
    }

    const getPolicys = async() => {
        try {
            const policys = await policyApi.getPolicys(serialNr, Globaldate);
            console.log(policys, "Raw policy data")
            if (!policys || policys.policy.length < 1) {
                return null
            } else {
                let maxValue = -Infinity;
                const reformattedPolicys = []
                for (let i = 0; i < policys.powerSetpoint_W.length; i++) {
                    const currentValue = policys.powerSetpoint_W[i];
    
                    reformattedPolicys.push({date: new Date(policys.time[i]), policypos: currentValue < 0 ? Math.abs(currentValue / 1000) : 0, policyneg: currentValue > 0 ? Math.abs(currentValue / 1000) : 0})
    
                    if (Math.abs(currentValue) > maxValue) {
                        maxValue = Math.abs(currentValue);
                    }
                }
                setPolicyMaxValue(maxValue)
                //reformat again to use vertical and horizontal lines
                let horverReformat = []
                let prevValue = null;
                if (reformattedPolicys[reformattedPolicys.length - 1].policyneg != 0 || reformattedPolicys[reformattedPolicys.length - 1].policypos != 0) {

                    reformattedPolicys.forEach((value, index) => {
                        if (index > 0) {
                            let newDate = new Date(value.date);
                            newDate.setTime(newDate.getTime() - 1000);
                            horverReformat.push({
                                date: newDate,
                                policypos: prevValue.policypos,
                                policyneg: prevValue.policyneg
                            })
                        }
                        prevValue = value
                        horverReformat.push(value)
                        if (index == reformattedPolicys.length - 1) {
                            let newDate = new Date();
                            horverReformat.push({
                                date: newDate,
                                policypos: reformattedPolicys[index].policypos,
                                policyneg: reformattedPolicys[index].policyneg
                            })
                        }
                    })
                } else {
                    reformattedPolicys.forEach((value, index) => {
                        if (index > 0) {
                            let newDate = new Date(value.date);
                            newDate.setTime(newDate.getTime() - 1000);
                            horverReformat.push({
                                date: newDate,
                                policypos: prevValue.policypos,
                                policyneg: prevValue.policyneg
                            })
                        }
                        prevValue = value
                        horverReformat.push(value)
                    })
                }

                let formattedData = formatDataForChart(horverReformat)
                return formattedData
            }
        } catch (error) {
            console.log(error)
            return null
        }
    }

    const getDayAhead = async() => {
        try {
            let tommorow = new Date()
            tommorow.setDate(Globaldate.getDate() + 1);

            const dayahead = await userApi.getDayAheadPrice(Globaldate.toISOString().split("T")[0], tommorow.toISOString().split("T")[0]);

            const currentDate = new Date(Globaldate);
            const startOfCurrentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            const endOfCurrentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
            
            let startIndex = -1;
            let endIndex = -1;
            let maxValue = -Infinity;
            const newDayAhead = []
            for (let i = 0; i < dayahead.time.length; i++) {
                const dateObj = new Date(dayahead.time[i]);
                if (dateObj >= startOfCurrentDay && dateObj < endOfCurrentDay) {
                    if (startIndex === -1) {
                        startIndex = i;
                    }
                    endIndex = i;

                    if (dayahead.values[i] > maxValue) {
                        maxValue = dayahead.values[i]
                    }
                    newDayAhead.push({date: dateObj, dayahead: dayahead.values[i]})
                }
            }
            setdayAheadMaxValue(Math.ceil(maxValue / 25) * 25)
            let formattedData = formatDataForChart(newDayAhead)

            return formattedData
        } catch (error) {
            return null
        }
    }

    const getSOCdata = async(maxBattCap) => {
        try {
            // const soc = await graphValuesApi.getGraphValue("soc", serialNr, Globaldate, "5min");
            const preSoc = await graphValuesApi.getGraphData(serialNr, Globaldate, "5min");
            const soc = preSoc.battery_soc


            const newSOC = soc.values.map((charge, i) => {
                return {
                    date: new Date(soc.time[i]),
                    soc: maxBattCap == 0 ? charge * 100 : charge * maxBattCap
                }
            })

            let formattedData = formatDataForChart(newSOC)

            return formattedData
        } catch (error) {
            return null
        }
    }

    const getUnbalancedata = async() => {
        try {
            const data = await policyApi.getImbalance(serialNr, Globaldate);
            
            if (!data || !data.element || data.element != "yuso" || data.powerSetpoint_W.length < 1) {
                throw new Error("no correct data");
            }

            const dataReformat = []
            for (let i = 0; i < data.powerSetpoint_W.length; i++) {
                const dateObject = new Date(data.time[i])
                dataReformat.push({"date": dateObject, "value": data.powerSetpoint_W[i] == 0 ? 0 : data.powerSetpoint_W[i] > 0 ? 1000 : -1000})
            }

            const newReformat = []
            for (let i = 0; i < dataReformat.length; i++) {
                if (dataReformat[i].value != 0) {
                    newReformat.push({date: dataReformat[i].date, value: dataReformat[i].value})

                    let j = i + 1

                    while (true) {
                        if (j < dataReformat.length) {
                            if (dataReformat[j].value === dataReformat[i].value) {
                                //keep going
                                j++
                            } else {
                                newReformat.push({date: dataReformat[j - 1].date, value: dataReformat[j - 1].value})
                                newReformat.push({date: dataReformat[j].date, value: dataReformat[j].value})

                                i = j
                                break;
                            }
                        } else {
                            newReformat.push({date: dataReformat[j - 1].date, value: dataReformat[i].value})
                            break;
                        }
                    }
                } else {
                    newReformat.push({date: dataReformat[i].date, value: 0})
                }
            }

            let formattedData = formatDataForChart(newReformat)

            return formattedData
        } catch (error) {
            console.log(error)
            return null
            //trigger parent event
        }
        
    }

    const formatDataForChart = (data) => {
        return data.map(item => ({
            ...item,
            timestamp: item.date.getTime()  // Convert Date object to timestamp
        }));
    };

    function convertToISODate(time, baseDate) {
        
        const currentDate = baseDate.toISOString().split('T')[0] || new Date().toISOString().split('T')[0];

        return `${currentDate}T${time}`;
    }

    const handleLegendClick = (event) => {
        setActiveCharts((prevState) => ({
            ...prevState,
            [event.dataKey]: !prevState[event.dataKey]
        }));
    }

    const handleDateBackClick = () => {
        setDate(subDays(Globaldate, 1))
    }
    
    const handleDateForwardClick = () => {
        if (!isToday(Globaldate)) {
            setDate(addDays(Globaldate, 1));
        }
        
    }

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
    }

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc" }}>
                    <p>{`Time: ${new Date(label).toLocaleTimeString()}`}</p>
                    {payload.map((item, index) => (
                        <p key={index} style={{ color: item.stroke || item.fill }}>
                            {`${item.name}: ${item.value}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const ChartComponent = () => {
        return (
            <>
            {loading ? 
                <img style={{margin: 'auto'}} className='loading-gif' src={loaderGIF} alt="" />    
                :
                <>
                <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Date-Container'>
                    <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
                        <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
                        <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
                    </div>
                    <div>{formatDate(Globaldate)}</div>
                    <div className='GraphDisplay-Date-Changer' style={{ visibility: isToday(Globaldate) ? 'hidden' : 'visible' }} onClick={handleDateForwardClick}>
                        <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
                        <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
                    </div>
                </div>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                        data={data}
                        margin={{ top: 30, right: 50, left: 10, bottom: 10 }}
                    >
                        <defs>
                            <linearGradient id="socColor" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#55D6C2" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#55D6C2" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="policysPos" x1="0" y1="1" x2="0" y2="0">
                                <stop offset="0%" stopColor="#A8E6CF" stopOpacity={0.5}/>
                                <stop offset="100%" stopColor="#4CAF50" stopOpacity={1}/>
                            </linearGradient>
                            <linearGradient id="policysNeg" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="30%" stopColor="#BC2924" stopOpacity={0.5}/>
                                <stop offset="100%" stopColor="#D83530" stopOpacity={1}/>
                            </linearGradient>
                        </defs>
                        {darkMode ? <CartesianGrid style={{opacity: 0.30}} strokeDasharray="10 10"/> : <CartesianGrid strokeDasharray="3 3" />} 
                        <XAxis 
                            dataKey="timestamp" 
                            type="number" 
                            domain={['dataMin', 'dataMax']} 
                            ticks={hourlyTicks} 
                            tickFormatter={(tick) => format(new Date(tick), 'HH:mm')} 
                            interval={3}
                        />

                        <YAxis hide={hasPolicys} yAxisId={"socAxis"} domain={maxBattCap == 0 ? [-100, 100] : [-maxBattCap , maxBattCap]} tickFormatter={(tick) => tick >= 0 ? `${tick}%` : ''}/>
                        <YAxis orientation='right' unit={'€/MWh'} hide={false} yAxisId={"dayaheadAxis"}  ticks={[-dayAheadMaxValue, -dayAheadMaxValue/2, 0, dayAheadMaxValue/2, dayAheadMaxValue]}/>
                        <YAxis domain={[-1000, 1000]} hide={true}/>
                        <YAxis yAxisId={"policyAxis"} hide={!hasPolicys} domain={[-maxBattCap, maxBattCap]} unit={'kW'} />
                        <Tooltip content={<CustomTooltip />}/>
                        <Legend onClick={handleLegendClick} wrapperStyle={{ color: '#555', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }}/>
                        {activeCharts.soc ? <Line yAxisId={"socAxis"} legendType='plainline' type="monotone" name='State of charge' connectNulls={true} dataKey="soc" strokeWidth={2} stroke="#55D6C2" dot={false} /> : <Line activeDot={false} legendType='plainline' yAxisId={"socAxis"} type="monotone" name='State of charge' connectNulls={true} dataKey="soc" strokeWidth={0} stroke="rgba(85, 214, 194, 0.4)" dot={false} />}
                        {foundUnbalanceData && (activeCharts.value ? <Line type="monotone" connectNulls={true} name='Imbalance signal' dataKey="value" strokeWidth={2} stroke="#F09D9D" dot={false} /> : <Line type="monotone" connectNulls={true} activeDot={false} name='Imbalance signal' dataKey="value" strokeWidth={0} stroke="rgba(240, 157, 157, 0.4)" dot={false} />)}
                        {activeCharts.dayahead ? <Line yAxisId={"dayaheadAxis"} legendType='plainline' type="monotone" name='Day-ahead pricing' connectNulls={true} dataKey="dayahead" strokeWidth={2} stroke="#D79928" dot={false} /> : <Line activeDot={false} legendType='plainline' yAxisId={"dayaheadAxis"} type="monotone" name='Day-ahead pricing' connectNulls={true} dataKey="dayahead" strokeWidth={0} stroke="rgba(215, 153, 40, 0.4)" dot={false} />}
                        {hasPolicys && (activeCharts.policypos ? <Area yAxisId={"policyAxis"} legendType='circle' type="monotone" connectNulls={true} name='Charging' dataKey="policypos" fill='url(#policysPos)' strokeWidth={0} stroke="#4CAF50" dot={false} /> : <Area activeDot={false} yAxisId={"policyAxis"} legendType='circle' type="monotone" name='Charging' dataKey="policypos" fill='none' strokeWidth={0} stroke="rgba(76, 175, 80, 0.4)" dot={false} />)}
                        {hasPolicys && (activeCharts.policyneg ? <Area yAxisId={"policyAxis"} legendType='circle' type="monotone" connectNulls={true} name='Discharging' dataKey="policyneg" fill='url(#policysNeg)' strokeWidth={0} stroke="#D83530" dot={false} /> : <Area activeDot={false} yAxisId={"policyAxis"} legendType='circle' type="monotone" name='Discharging' dataKey="policyneg" fill='none' strokeWidth={0} stroke="rgba(216, 53, 48, 0.4)" dot={false} />)}
                    </ComposedChart>
                </ResponsiveContainer>
                </>
                }
            </>
        );
    };

    return (
        <div style={data && data.length > 0 ? {visibility: 'visible'} : {visibility: 'hidden'}} className="dashboard__savings__container">
            {/* <div className='dashboard__savings__imbalance'>Imbalance</div> */}
            <ChartComponent />
            
            {/* Uncomment and adjust these sections as needed */}
            {/* <div className={`dashboard__savings__underdevelopment__container__${darkMode ? 'darkmode' : 'lightmode'}`}>
                <div className={`dashboard__savings__underdevelopment__message__${darkMode ? 'darkmode' : 'lightmode'}`}>
                    This section is currently under development and will be available soon.
                </div>
            </div> */}
            {/* <div className='dashboard__savings__header'>Savings</div>
            <div className='dashboard__savings__content'>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Yesterday</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={yesterDaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{yesterDaySavings}€</div>
                </div>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Today</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={todaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{todaySavings}€</div>
                </div>
            </div> */}
        </div>
    );
};

export default DashboardSavings;