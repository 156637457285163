import React, { useEffect } from 'react';
import './controllerPage.css';
import grafiek from '../../../Images/Grafiek.png'
import controller from '../../../Images/Controller.png';

const ControllerPage = () => {

    useEffect(() => {
        const hash = window.location.hash;
        setTimeout(() => {
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView();
                }
            }
        }, 0)
    }, []);

    return (
        <div className="homePage__controller__container">
            <section className="homePage__controller__introduction">
                <h1>Hoe Werken Wij</h1>
                <p>Bij FIRN energy richten we ons op geavanceerd energiebeheer om uw energiekosten tot een minimum te beperken. Onze oplossingen combineren technologie en slimme strategieën om uw energieverbruik en productie te optimaliseren.</p>
            </section>

            <section className="homePage__controller__energy-management">
                <h2>Onze Aanpak voor Energiebeheer</h2>
                <p>
                    FIRN energy biedt een geïntegreerde oplossing voor het beheer van diverse energiebronnen zoals zonnepanelen, windenergie en laadpalen. Hier is een overzicht van onze belangrijkste aanpakken:
                </p>
                <h3>1. Verhoging van Eigenverbruik</h3>
                <p>
                    Door de installatie van slimme batterijen kunnen we een aanzienlijk deel van de door uw zonnepanelen geproduceerde energie opslaan en later gebruiken wanneer de vraag hoog is of wanneer de energieprijzen het hoogst zijn. Dit vermindert de afhankelijkheid van het elektriciteitsnet en verlaagt de energiekosten aanzienlijk.
                </p>
                <ul>
                    <li><strong>Energieopslag:</strong> We slaan overtollige zonne-energie op voor later gebruik, vooral tijdens piekuren of wanneer de zon niet schijnt.</li>
                    <li><strong>Kostenbesparing:</strong> Door minder energie aan te kopen van het net, bespaart u op zowel de energieprijs als de transport- en distributiekosten.</li>
                </ul>
                <img src={grafiek} alt="Grafiek" />

                <h3>2. Optimalisatie van Aankoop en Verkoop</h3>
                <p>
                    FIRN energy optimaliseert de aankoop van energie door gebruik te maken van dynamische tarieven zoals day-ahead prijzen, die gebaseerd zijn op de voorspellingen van de energiebehoefte en -aanbod op de markt.
                </p>
                <ul>
                    <li><strong>Aankoopstrategie:</strong> De FIRN Controller koopt energie in op momenten wanneer de prijzen het laagst zijn, bijvoorbeeld 's nachts of tijdens daluren.</li>
                    <li><strong>Verkoopstrategie:</strong> Overtollige energie die u zelf niet verbruikt, wordt verkocht op momenten wanneer de marktprijzen het hoogst zijn, waardoor u de meeste winst kunt maken.</li>
                    <li><strong>Belpex prijzen:</strong> We maken gebruik van Belpex day-ahead prijzen, die dagelijks worden vastgesteld, om zowel de aankoop als verkoop van energie te optimaliseren.</li>
                </ul>

                <h3>3. Gebruik van Markt-Onbalans</h3>
                <p>
                    Op het Belgische elektriciteitsnet ontstaan regelmatig onbalansen tussen vraag en aanbod. FIRN energy maakt gebruik van deze onbalansprijzen om extra inkomsten te genereren voor onze klanten.
                </p>
                <ul>
                    <li><strong>Onbalansopportuniteiten:</strong> De FIRN Controller anticipeert op momenten van onbalans en stuurt het systeem zodanig dat u hiervan profiteert, door bijvoorbeeld energie terug te leveren aan het net op het juiste moment.</li>
                    <li><strong>Realtime monitoring:</strong> Door constante monitoring van het netwerk en de onbalansprijzen kan de FIRN Controller direct inspelen op veranderingen en de efficiëntie van uw energiebeheer verder optimaliseren.</li>
                </ul>

                <h3>4. Peak Shaving</h3>
                <p>
                    Peak shaving is een techniek waarbij we het verbruik tijdens piekuren verlagen om te besparen op de capaciteitskosten. Dit is vooral belangrijk in omgevingen waar hoge piekbelastingen kunnen leiden tot aanzienlijke extra kosten.
                </p>
                <ul>
                    <li><strong>Capaciteitskosten verlagen:</strong> Door pieken in het verbruik af te vlakken, verminderen we de maximale belasting en daarmee de kosten die hieraan verbonden zijn.</li>
                    <li><strong>Batterijbeheer:</strong> De FIRN Controller zorgt ervoor dat de batterij juist wordt ingezet tijdens deze piekuren om het verbruik van het net te minimaliseren.</li>
                </ul>
            </section>

            <section id="firn-controller" className="homePage__controller__firn-controller">
                <div className="homePage__controller__content">
                    <div>
                        <h2>De FIRN Controller</h2>
                        <p>
                            De FIRN Controller is het hart van ons energiebeheersysteem. Deze geavanceerde, AI-gestuurde controller zorgt ervoor dat uw energieverbruik en -productie continu worden geoptimaliseerd.
                        </p>
                    </div>
                    <img src={controller} alt="FIRN Controller" className="homePage__controller__controllerImage" />
                </div>
                <h3>Hoe Werkt de FIRN Controller?</h3>
                <ul>
                    <li><strong>Voorspellingen van Zonnepaneelproductie:</strong> De controller maakt gebruik van geavanceerde weersmodellen om nauwkeurige voorspellingen te doen van de zonne-energieproductie. Deze voorspellingen worden verder verfijnd door kalibratie op uw specifieke installatie.</li>
                    <li><strong>Verbruik Voorspellen:</strong> Gebaseerd op historisch verbruik, voorspelt de FIRN Controller uw energiebehoefte voor de komende dagen, waardoor een geoptimaliseerde planning van energiegebruik mogelijk is.</li>
                    <li><strong>Adaptieve AI:</strong> De controller leert continu van de prestaties en aanpassingen in uw systeem, waardoor deze steeds beter wordt in het optimaliseren van uw energiebeheer.</li>
                    <li><strong>Realtime Aanpassingen:</strong> Op basis van de voorspellingen en actuele marktdata, past de FIRN Controller het energiebeheer aan om kosten te besparen en efficiëntie te maximaliseren.</li>
                </ul>
            </section>

            <section className="homePage__controller__additional-control">
                <h2>Extra Sturingsmogelijkheden</h2>
                <p>
                    FIRN energy biedt naast het beheer van batterijen en zonnepanelen ook extra sturingsmogelijkheden om uw energieverbruik verder te optimaliseren:
                </p>
                <h3>1. Verbruikers Aansturen</h3>
                <p>
                    Grote energieverbruikers, zoals laadpalen en verwarmingssystemen, kunnen worden aangestuurd om te functioneren tijdens momenten van lage energieprijzen of overschotten van zonne-energie. Dit verlaagt uw totale energiekosten en verhoogt de efficiëntie van uw systeem.
                </p>

                <h3>2. Warmwatergeneratie</h3>
                <p>
                    De FIRN Controller kan warmwaterboilers sturen om water te verwarmen op momenten dat de energieprijzen laag zijn of er een overschot aan zonne-energie is. Dit verlaagt de kosten van warmwaterproductie en benut de beschikbare energie optimaal.
                </p>

                <h3>3. Capaciteitsmanagement</h3>
                <p>
                    Door het verbruik te sturen en te optimaliseren, helpen we om het capaciteitsgebruik tijdens piekuren te verminderen. Dit leidt tot een lagere energierekening door verlaging van de capaciteitstarieven.
                </p>

                <h3>4. Curtailment-strategieën</h3>
                <p>
                    Bij een overaanbod aan energie op het net kan de FIRN Controller strategisch het verbruik verhogen, energie opslaan of de productie van PV-systemen tijdelijk verminderen om te voorkomen dat u negatieve injectietarieven moet betalen.
                </p>
                <ul>
                    <li><strong>Verhoging van verbruik:</strong> Schakel bepaalde apparaten in of verhoog hun werking om overtollige energie te verbruiken.</li>
                    <li><strong>Energieopslag:</strong> Sla overtollige energie op in de batterij voor later gebruik wanneer de vraag hoger is.</li>
                    <li><strong>Vermindering van PV-productie:</strong> De controller kan tijdelijk de productie van zonne-energie verminderen om te voorkomen dat deze energie tegen een negatieve prijs moet worden geïnjecteerd.</li>
                    <li><strong>Externe PV-omvormers uitschakelen:</strong> In extreme gevallen kunnen externe PV-omvormers worden uitgeschakeld om overbelasting te voorkomen.</li>
                </ul>
            </section>
        </div>
    );
}

export default ControllerPage;