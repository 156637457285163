import { useEffect, useRef, useState } from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer, CartesianGrid } from 'recharts';
import User from '../../../api/user';
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";
import "./dayAheadPriceChart.css";

const DayAheadPriceChart = ({ dateToday }) => {
    const [data, setData] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date(dateToday));
    const [domainValues, setDomainValues] = useState([-1, 1]);
    const userApi = User();

    const containerRef = useRef(null);
    const [responsive, setResponsive] = useState(false);

    useEffect(() => {
        fetchData();
    }, [currentDate]);

    useEffect(() => {
        const checkResponsive = () => {
            if (containerRef.current.offsetWidth < 550) {
                setResponsive(true)
            } else {
                setResponsive(false)
            }
        }

        checkResponsive();
        window.addEventListener('resize', checkResponsive)

        return () => {
            window.removeEventListener('resize', checkResponsive)
        }

    }, [])

    const roundToNearest = (value) => {
        const remainder = value % 0.005;
    
        if (remainder >= 0.0025) {
            return value + (0.005 - remainder);
        } else {
            return value - remainder;
        }
    };    

    const fetchData = async () => {
        try {
            let tommorow = new Date()
            tommorow.setDate(currentDate.getDate() + 1);
            const response = await userApi.getDayAheadPrice(currentDate.toISOString().split("T")[0], tommorow.toISOString().split("T")[0]);

            const dataWithConvertedPrice = response.time.map((timestamp, i) => {
                if (timestamp.split("T")[0] === currentDate.toISOString().split("T")[0]) {
                    return { dateTime: timestamp, price: response.values[i] };
                }
                return null;
            }).filter(item => item !== null);

            let highestPrice = Math.max(...dataWithConvertedPrice.map(item => item.price));
            let lowestPrice = Math.min(...dataWithConvertedPrice.map(item => item.price));

            if (lowestPrice > 0) {
                lowestPrice = 0;
            }
            if (highestPrice < 0) {
                highestPrice = 0;
            }

            const roundedLowestPrice = roundToNearest(lowestPrice);
            const roundedHighestPrice = roundToNearest(highestPrice);

            setDomainValues([roundedLowestPrice, roundedHighestPrice]);
            setData(dataWithConvertedPrice);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const OnBackButtonClick = () => {
        const selectedDay = new Date(currentDate);
        const today = new Date();

        const minAllowedDate = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));

        if (!(selectedDay.getTime() < minAllowedDate.getTime())) {
            selectedDay.setDate(selectedDay.getDate() - 1);
            setCurrentDate(selectedDay);
        }
    };

    const OnForwardButtonClick = () => {
        const selectedDay = new Date(currentDate);
        const maxAllowedDate = new Date();

        if (selectedDay.getTime() + (1 * 24 * 60 * 60 * 1000) < maxAllowedDate.getTime()) {
            selectedDay.setDate(selectedDay.getDate() + 1);
            setCurrentDate(selectedDay);
        }
    };

    return (
        <div className="DayAheadPriceChartContainer">
            <ResponsiveContainer ref={containerRef} width={"100%"} height={"100%"} className="DayAheadPriceChartResponsiveContainer">
                <AreaChart data={data} >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#A46648" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#A46648" stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.2)" />
                    <XAxis
                        stroke="white"
                        dataKey="dateTime"
                        interval={responsive ? 2 : 1}
                        tickFormatter={(value) => {
                            const date = new Date(value);
                            return `${date.getHours()}u`;
                        }}
                    />
                    <YAxis
                        stroke='white'
                        dataKey="price"
                        unit="€"
                        domain={[domainValues[0] === 0 ? 0 : domainValues[0], domainValues[1]]}
                        fontSize={responsive ? '10px' : '16px'}
                        width={responsive ? 40 : 60}
                        tickFormatter={(value) => roundToNearest(value).toFixed(1)}
                    />
                    <Tooltip
                        labelFormatter={(value) => new Date(value).toLocaleString()}
                        formatter={(value) => {
                            return [<span style={{ color: '#A46648', fontWeight: 'bold' }}>{`Price: ${value} €/MWh`}</span>];
                        }}
                        contentStyle={{ backgroundColor: "white", color: "#333" }}
                        itemStyle={{ color: "#333" }}
                        className="DayAheadPriceChartTooltipContent"
                    />
                    <Area type="monotone" dataKey="price" stroke="#A46648" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>
            <div className="DayAheadPriceChartGrid">
                <div className="DayAheadPriceChartButtonContainer">
                    <button className='DayAheadPriceChartBackBtn' onClick={OnBackButtonClick}>
                        <IoIosArrowDropleftCircle className="DayAheadPriceChartIcon DayAheadPriceChartBackBtnIcon" />
                    </button>
                </div>
                <div className="DayAheadPriceChartDate">{currentDate.toLocaleDateString()}</div>
                <div className='DayAheadPriceChartForwardBtn' style={{ display: "flex", alignItems: "center", padding: "0px 5px" }}>
                    <button className='DayAheadPriceChartBackBtn' onClick={OnForwardButtonClick}>
                        <IoIosArrowDroprightCircle className="DayAheadPriceChartIcon DayAheadPriceChartForwardBtnIcon" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DayAheadPriceChart;
