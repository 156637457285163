import React from 'react';
import './contactPage.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import fotoMap from "../../../Images/Google-Maps-adres.png"
import fotoFrank from "../../../Images/frankPicture.png"
import fotoNico from "../../../Images/nicoPicture.png"

const ContactPage = () => {
    return (
        <div className="contactPage__body">
            <div className="contactPage__grid">
                <div className="contactPage__segment contactPage__segment--details">
                    <div className="contactPage__header">
                        <h2>Samen energie besparen</h2>
                        <p>Heb je vragen over ons product of platform? We helpen je graag!</p>
                    </div>
                    <address>
                        <i className='fas fa-location-dot' /><a href="https://www.google.com/maps/dir/?api=1&destination=Parkstraat+23,+Menen,+België" target="_blank" rel="noopener noreferrer" >
                            Parkstraat 23, 8930 Menen</a><br />
                        <i className='fas fa-phone' />+32 56 19 88 77<br />
                        <i className='fas fa-envelope' /><a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a><br />
                    </address>
                </div>

                <a href="https://www.google.com/maps/dir/?api=1&destination=Parkstraat+23,+Menen,+België" target="_blank" rel="noopener noreferrer" >
                    <img src={fotoMap} alt="Adres" className="contactPage__segment contactPage__segment--route" />
                </a>

                <div className="contactPage__segment contactPage__segment--founders">
                    <h2>Onze Oprichters</h2>
                    <div className="contactPage__founder">
                        <img src={fotoFrank} alt="Frank Degezelle" />
                        <div className="contactPage__founder__info">
                            <h3>Frank Degezelle</h3>
                            <p>“Ontgrendel het volledige potentieel van uw zonne-installatie met FIRN - uw sleutel tot maximale energieopbrengst.”</p>
                        </div>
                    </div>
                    <div className="contactPage__founder">
                        <img src={fotoNico} alt="Nico Ramacker" />
                        <div className="contactPage__founder__info">
                            <h3>Nico Ramacker</h3>
                            <p>“Onze energie-efficiëntie maximaliseren is niet alleen een keuze, het is een noodzaak in de wereld van vandaag.”</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
