import { useEffect, useState } from 'react'
import Settings from '../../../../../../api/settings'

const SettingsChargingStationItem = ({serialNr, station}) => {

    const settingsApi = Settings()

    const [stations, setStations] = useState([
        {title: "Name", desc: station.name, edit: {active: false, prevVal: ''}},
        {title: "Node_id", desc: station.node_id, edit: {active: false, prevVal: ''}},
        {title: "Provider", desc: station.gateway, edit: {active: false, prevVal: ''}}
    ])

    useEffect(() => {
        setStations([
            {title: "Name", desc: station.name, edit: {active: false, prevVal: ''}},
            {title: "Node_id", desc: station.node_id, edit: {active: false, prevVal: ''}},
            {title: "Provider", desc: station.gateway, edit: {active: false, prevVal: ''}}
        ])
    }, [serialNr, station])

    // const handleEdit = (index) => {
    //     const prevValue = relais[index].desc

    //     setRelais((prevRelais) =>
    //         prevRelais.map((item, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...item,
    //                     desc: (
    //                         <input 
    //                             className='settings__page__item__input'
    //                             type="text" 
    //                             value={prevValue}
    //                             onChange={(e) => handleInputChange(e, i)}
    //                         />
    //                     ),
    //                     edit: { ...item.edit, active: true, prevVal: prevValue},
    //                 };
    //             }
    //             return item;
    //         })
    //     );
    // }

    // const handleCancel = (index) => {
    //     const prevValue = relais[index].edit.prevVal

    //     const cancelRelais = relais.map((item, i) => {
    //         if (i === index) {
    //           return { ...item,
    //             desc: prevValue,
    //             edit: { ...item.edit, active: false, prevVal: ''} };
    //         }
    //         return item;
    //     });
    //     setRelais(cancelRelais);
    // }

    // const handleSave = (index) => {
    //     const desiredValue = relais[index].desc.props.value
    //     if (desiredValue === "") {
    //         //show error cannot be empty
    //     } else {
    //         const saveRelais = relais.map((item, i) => {
    //             if (i === index) {
    //               return { ...item, 
    //                 desc: desiredValue,
    //                 edit: { ...item.edit, active: false, prevVal: ''} };
    //             }
    //             return item;
    //         });
    //         setRelais(saveRelais);
    //     }

        
    // }

    const handleInputChange = (e, index) => {
        setStations((prevStations) => 
            prevStations.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        desc: 
                        <input 
                            className='settings__page__item__input'
                            type="text" 
                            value={e.target.value}
                            onChange={(e) => handleInputChange(e, i)}
                        />
                    }
                }
                return item;
            }
        ))
    }

    // const [pvNodes] = useState(["pvNode1", "pvNode2", "pvNode3"])

    useEffect(() => {
        let index = 0

        switch (station.task.mode) {
            case "slow_charge":
                index = 1
                setModes((prevModes) =>
                    prevModes.map((mode, index) => {
                      if (index === 1) {
                        return {
                          ...mode,
                          val: station.task.endTime ? station.task.endTime : 1
                        };
                      }
                      return mode;
                    })
                );
                break;
            default:
                index = 0
                break;
        }

        setSelectedMode(index);
        setOriginalSelectedMode(index);

        setOriginal(modes)

    }, [station])

    const [modes, setModes] = useState([{name: 'Stand-By', val: 0}, {name: 'slow_charge', val: ''}])
    const [original, setOriginal] = useState([{name: 'Stand-By', val: 0}, {name: 'slow_charge', val: ''}])
 
    const [originalSelectedMode, setOriginalSelectedMode] = useState(0);
    const [selectedMode, setSelectedMode] = useState(0);

    const [changes, setChanges] = useState(false)
    
    const [standByHovered, setStandByHovered] = useState(false)
    const [slowChargeHovered, setSlowChargeHovered] = useState(false)

    useEffect(() => {
        //needs to be updated check only selected mode value
        if (selectedMode !== originalSelectedMode || modes[selectedMode].val != original[selectedMode].val) {
            setChanges(true)
        } else {
            setChanges(false)
        }
        

    }, [selectedMode, modes]);

    const handleModeCancel = () => {
        setSelectedMode(originalSelectedMode);

        setModes((prevMode) => 
            prevMode.map((mode, i) => {
                return {
                    ...mode,
                    val: original[i].val
                }
            })
        )

        setChanges(false)
    }

    const handleModeSave = async() => {
    
        setOriginalSelectedMode(selectedMode);

        setOriginal((prevOriginal) => 
            prevOriginal.map((item, i) => {
                if (i === selectedMode) {
                    return {
                        ...item,
                        val: modes[i].val
                    }
                }
                return item;
            })
        )

        //send update to api
        // const checkData = {
        //     gateway: relay.gateway,
        //     mode: modes[selectedMode].name == 'Stand-By' ? '': modes[selectedMode].name,
        //     n: parseInt(modes[2].val),
        //     name: relay.name,
        //     node_id: relay.node_id,
        //     pv_node_name: modes[1].val,
        //     relais_id: relay.id,
        //     user_id: modes[3].val,
        //     date: new Date(modes[4].val).toISOString(),
        //     status: Boolean(status),
        //     operator: operator,
        //     price: parseInt(modes[5].val)
        // }

        // const response = await settingsApi.updateRelais(serialNr, checkData);

        setChanges(false)
    }

    const handleModeValSwitch = (value, index) => {

        setModes((prevMode) => 
            prevMode.map((mode, i) => {
                if (i === index) {
                    return {
                        ...mode,
                        val: value
                    }
                }
                return mode
            })
        )

    }

    return (
        <div className="settings__page__items">
            {stations.map((item, i) => 
                <div className="settings__page__item">
                    <div className="settings__page__item__info">
                        <div className="settings__page__item__title">{item.title}</div>
                        <div className="settings__page__item__desc">{item.desc}</div>
                    </div>
                    {/* {!item.edit.active && <div className="settings__page__item__edit" onClick={() => handleEdit(i)}>Edit</div>}
                    {item.edit.active && 
                    <div className="settings__page__item__edit--editing">
                        <div className="settings__page__item__save" onClick={() => handleSave(i)}>Save</div>
                        <div className="settings__page__item__cancel" onClick={() => handleCancel(i)}>Cancel</div>
                    </div>    
                    } */}
                </div>
            )}
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Task</div>
                    <div className='settings__page__item__radio__selection'>
                        {/* Stand-By */}
                        <div className={`settings__page__item__radio__card ${selectedMode == 0 && 'selected'}`} onClick={() => setSelectedMode(0)} onMouseEnter={() => setStandByHovered(true)} onMouseLeave={() => setStandByHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div>Stand-By</div>
                                    <div className={`settings__page__item__radio__button ${standByHovered && selectedMode != 0 && 'hovered'} ${selectedMode == 0 && 'selected'}`}>{selectedMode == 0 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Sets the charging station into a stand-by mode where it waits on your input to function</div>
                            </div>
                        </div>
                        {/* slow charge */}
                        <div className={`settings__page__item__radio__card ${selectedMode == 1 && 'selected'}`} onClick={() => setSelectedMode(1)} onMouseEnter={() => setSlowChargeHovered(true)} onMouseLeave={() => setSlowChargeHovered(false)}>
                            <div className='settings__page__item__radio__card__content'>
                                <div className='settings__page__item__radio__header'>
                                    <div>Slow Charge</div>
                                    <div className={`settings__page__item__radio__button ${slowChargeHovered && selectedMode != 1 && 'hovered'} ${selectedMode == 1 && 'selected'}`}>{selectedMode == 1 && <div className='settings__page__item__radio__button__selected'></div>}</div>
                                </div>
                                <div className='settings__page__item__radio__desc'>Slow charge your vehicle until end time</div>
                                <div style={{marginTop: 10}}>
                                    <input className='settings__page__item__input' type="number" min={1} max={24} value={modes[1].val} onChange={(e) => handleModeValSwitch(e.target.value, 1)}/>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            {changes && 
                <div className="settings__page__item__edit--editing" style={{marginTop: 10, justifyContent: 'flex-end'}}>
                    <div className="settings__page__item__save" onClick={() => handleModeSave()}>Save Changes</div>
                    <div className="settings__page__item__cancel" onClick={() => handleModeCancel()}>Cancel</div>
                </div> 
            }
            <div className="settings__page__item__work-in-progress">
                This section is a work in progress.
            </div>
        </div>
    )
}

export default SettingsChargingStationItem