import { useState } from "react"
import { useForm} from "react-hook-form"
import errorIcon from "../../../../../Images/errorIcon.png";

import Settings from "../../../../../api/settings"

const SettingsRelais = ({serialNr}) => {

    const settingsApi = Settings()

    const {register, handleSubmit, watch, formState: { errors }} = useForm();
    const onSubmit = async(data) => {
        
        const check = await settingsApi.addRelais(serialNr, data);
    }
    const [createRelais, setCreateRelais] = useState(false)

    return (
        <div className="settings__page__items">
            { !createRelais &&
                <>
                    <div className="settings__page__item">
                        <div className="settings__page__item__info">
                            <div className="settings__page__item__title">Relais</div>
                            <div className="settings__page__item__desc">Add a relais</div>
                        </div>
                        <div className="settings__page__item__create" onClick={() => setCreateRelais(true)}>Add</div>
                    </div>
                </>
            }
            {   createRelais &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="settings__page__item">
                        <div className="settings__page__item__info">
                            <div className="settings__page__item__title">Name</div>
                            <input className="settings__page__item__input" {...register("name", {required: "Name cannot be empty"})}/>
                            {errors.name && <div className="settings__page__item__input__error"><img height={22} src={errorIcon} alt="errorIcon" /><div>{errors.name.message}</div></div>}
                        </div>
                    </div>
                    <div className="settings__page__item">
                        <div className="settings__page__item__info">
                            <div className="settings__page__item__title">Node Id</div>
                            <input className="settings__page__item__input" {...register("nodeId", {required: "Node Id cannot be empty"})}/>
                            {errors.nodeId && <div className="settings__page__item__input__error"><img height={22} src={errorIcon} alt="errorIcon" /><div>{ errors.nodeId?.message}</div></div>}
                        </div>
                    </div>
                    <div className="settings__page__item">
                        <div className="settings__page__item__info">
                            <div className="settings__page__item__title">Gateway</div>
                            <input className="settings__page__item__input" {...register("gateway", {required: "Gateway cannot be empty"})}/>
                            {errors.gateway && <div className="settings__page__item__input__error"><img height={22} src={errorIcon} alt="errorIcon" /><div>{ errors.gateway?.message}</div></div>}
                        </div>
                    </div>
                    

                    <button type="sumbit" style={{marginTop: 10}} className="settings__page__item__create">Create</button>
                </form>
            }
        </div>
    )
}

export default SettingsRelais