import React from 'react';
import './powerblock.css';

import powerblock from '../../../../Images/powerblock.png';
import powerblockOpen from '../../../../Images/powerblockOpen.png';

const Powerblock = () => {
    return (
        <div className="powerblockPage__container">
            <section className="powerblockPage__introduction">
                <h1>FIRN Power Block 233</h1>
                <p>
                    De FIRN Power Block 233 is een alles-in-één energieopslagsysteem (ESS) met geavanceerde vloeistofkoeling en een hoge energiedichtheid. Dit systeem is speciaal ontworpen voor grootschalige energieopslagprojecten en biedt flexibele uitbreidingsmogelijkheden voor commerciële en industriële toepassingen.
                </p>
            </section>

            <section className="powerblockPage__content">
                <div className="powerblockPage__text-content">
                    <div className="powerblockPage__text">
                        <h2>Waarom kiezen voor de FIRN Power Block 233?</h2>
                        <p>
                            Het modulaire ontwerp van de Power Block maakt het mogelijk om flexibel uit te breiden en heeft een hogere energiedichtheid, wat ruimte bespaart en zorgt voor een lange levensduur van meer dan 8000 cycli. Daarnaast maakt het gebruik van vloeistofkoeling voor efficiënte temperatuurregeling, wat de prestaties van het systeem verder optimaliseert.
                        </p>
                        <ul>
                            <li><strong>Hoge vermogensdichtheid:</strong> 233 kWh energieopslag in één kast.</li>
                            <li><strong>Lange levensduur:</strong> Meer dan 8000 cycli dankzij temperatuurregeling.</li>
                            <li><strong>Flexibele uitbreiding:</strong> Modulair ontwerp maakt parallelle uitbreiding eenvoudig.</li>
                            <li><strong>Veiligheid:</strong> Drievoudige brandveiligheid met detectie en actieve waarschuwing.</li>
                        </ul>
                    </div>
                    
                    <div className="powerblockPage__features">
                        <h2>Kenmerken</h2>
                        <ul>
                            <li><strong>Hoge vermogensdichtheid:</strong> 233 kWh per kast</li>
                            <li><strong>Ultieme veiligheid:</strong> Detectie van brandbare gassen en rook, actieve brandonderdrukking.</li>
                            <li><strong>Efficiënte koeling:</strong> Vloeistofkoeling voor consistente temperatuurregeling.</li>
                            <li><strong>Modulair ontwerp:</strong> Voor eenvoudige uitbreiding en installatie.</li>
                        </ul>
                    </div>
                </div>
                <div className="powerblockPage__image-block">
                    <img src={powerblock} alt="Powerblock 233" />
                </div>
            </section>

            <section className="powerblockPage__open-image">
                <img src={powerblockOpen} alt="Open Powerblock" />
            </section>
        </div>
    );
};

export default Powerblock;
