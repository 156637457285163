import { useState, useEffect } from 'react'
import { format } from 'date-fns'

export const initialKlantData = {
  num: "REF_OFFERTE",
  contactpersoonFirn: "nico",
  bedrijf: "",
  contactpersoon: "",
  email: "",
  tel: "",
  adres: "",
  postcode: "",
  gemeente: "",
  land: "",
  korting: 0,
  btw: "",
  geldigTot: format(new Date(new Date().setMonth(new Date().getMonth() + 1)), "yyyy-MM-dd"),
  betalingsvoorwaarde: "",
  verbruik: "",
  productie: "",
  injectie: "",
  aankoop: "",
  verkoop: "",
  aankoopExcl: "",
  aansluitvermogen: "",
  simOnbalans: 100,
  eco: "nee",
  vlif: "nee",
  invest: "nee"
}

export const useRequiredFieldsCheck = (fields, data) => {
  const [reqFull, setReqFull] = useState(true)

  useEffect(() => {
    const reqOK = fields.some(field => data[field] === "" || data[field] === 0)
    setReqFull(reqOK)
  }, [data, fields])

  return reqFull
}

export const initialOrder = {
  omvormer1: { key: "", num: 0 },
  omvormer2: { key: "", num: 0 },
  omvormer3: { key: "", num: 0 },
  batterijCapaciteit1: { key: "" },
  batterijCapaciteit2: { key: "" },
  batterijCapaciteit3: { key: "" },
  buitenbehuizing: { key: "", num: 0 },
  controller: { key: "", num: 0 },
  energiemeter: { key: "", num: 0 },
  abonnement: { key: "", num: 0 },
  kleineMaterialen: { total: 0, list: [] },
  btw: { key: "BTW"},
  bebat: { key: "2024" }
}

export const convertToOptions = (data) => {
  return Object.keys(data).map(key => ({
    key: key,
    value: key,
    text: data[key].name || data[key]
  }))
}
