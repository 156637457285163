import './homePageOurSolutions.css';
import { useNavigate } from 'react-router-dom';
import { oplossingenItems } from '../OplossingenPage/OplossingsItem';

const selectedIds = [1, 2, 3];

const HomePageOurSolutions = () => {
    const navigate = useNavigate();

    const selectedSolutions = oplossingenItems.filter(item => selectedIds.includes(item.id));

    return (
        <div className='homepage__oursolutions__container'>
            <div className='homepage__oursolutions__header__container'>
                <div className='homepage__oursolutions__header'>
                    <div className='homepage__oursolutions__header__pretitle'>
                        FIRN energy <span style={{ fontWeight: 'bold' }}>OPLOSSINGEN</span>
                    </div>
                    <div className='homepage__oursolutions__header__title'>Onze Oplossingen</div>
                    <div className='homepage__oursolutions__header__desc'>
                        FIRN energy biedt slimme en innovatieve oplossingen voor energiebeheer.
                        Onze systemen optimaliseren het verbruik van hernieuwbare energie, zoals zonne- en windenergie,
                        en helpen uw energiekosten te verlagen. Of het nu gaat om batterijopslag, laadpalen,
                        of slimme warmtepompen, wij bieden op maat gemaakte oplossingen die passen bij uw specifieke energiebehoeften.
                    </div>
                </div>
            </div>
            <div className='homepage__oursolutions__content__container'>
                {selectedSolutions.map(item => (
                    <div key={item.id} className='homepage__oursolutions__content__item'>
                        <div className='homepage__oursolutions__content__header'>
                            <img
                                className='homepage__oursolutions__content__image__one'
                                src={item.foto}
                                alt={item.naam}
                            />
                            <img
                                className='homepage__oursolutions__content__image__two'
                                src={item.foto}
                                alt={item.naam}
                            />
                        </div>
                        <div>
                            <div className='homepage__oursolutions__item__text__header'>{item.naam}</div>
                            <div className='homepage__oursolutions__item__text__desc'>{item.korteBeschrijving}</div>
                            <div>
                                {item.voordelen.map((voordeel, index) => (
                                    <div key={index} className='homepage__oursolutions__item__advantage'>
                                        <span>+</span>
                                        <div style={{ paddingTop: '3px' }}>{voordeel}</div>
                                    </div>
                                ))}
                            </div>
                            <div className='homepage__oursolutions__item__btn' onClick={() => navigate(`${item.link}`)}>
                                <div style={{ paddingTop: 2 }}>Ontdek<i className='fas fa-arrow-right' /></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomePageOurSolutions;
