import React from 'react';
import './sector.css';
import content from './SectorenContent.json';

const Sector = ({ sector }) => {
    const data = content[sector];

    if (!data) {
        return <div>Sector niet gevonden</div>;
    }

    return (
        <div className="sectoren__body">
            <h1 className="sectoren__header">{data.header}</h1>
            {data.sections.map((section, index) => (
                <section key={index} className="sectoren__section">
                    {section.styling?.className ? (
                        <>
                            <div className={`sectoren__text ${section.styling?.className || ''}`}>
                                {section.text.title && <h2>{section.text.title}</h2>}
                                {section.text.paragraphs && section.text.paragraphs.map((para, idx) => <p key={idx}>{para}</p>)}
                                {section.text.list && (
                                    <ul>
                                        {section.text.list.map((item, idx) => <li key={idx}>{item}</li>)}
                                    </ul>
                                )}
                                {section.text.details && section.text.details.map((detail, idx) => <p key={idx}>{detail}</p>)}
                                {section.text.title2 && <h2>{section.text.title2}</h2>}
                                {section.text.paragraphs2 && section.text.paragraphs2.map((para, idx) => <p key={idx}>{para}</p>)}
                            </div>
                            {section.image && (
                                <div className="sectoren__image">
                                    <img src={require(`../../../Images/${section.image}`)} alt={section.text.title || "Afbeelding"} />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {section.image && (
                                <img src={require(`../../../Images/${section.image}`)} alt={section.text.title || "Afbeelding"} className="sectoren__image" />
                            )}
                            <div className={`sectoren__text ${section.styling?.className || ''}`}>
                                {section.text.title && <h2>{section.text.title}</h2>}
                                {section.text.paragraphs && section.text.paragraphs.map((para, idx) => <p key={idx}>{para}</p>)}
                                {section.text.list && (
                                    <ul>
                                        {section.text.list.map((item, idx) => <li key={idx}>{item}</li>)}
                                    </ul>
                                )}
                                {section.text.details && section.text.details.map((detail, idx) => <p key={idx}>{detail}</p>)}
                                {section.text.title2 && <h2>{section.text.title2}</h2>}
                                {section.text.paragraphs2 && section.text.paragraphs2.map((para, idx) => <p key={idx}>{para}</p>)}
                                {section.text.title3 && <h2>{section.text.title3}</h2>}
                                {section.text.paragraphs3 && section.text.paragraphs3.map((para, idx) => <p key={idx}>{para}</p>)}
                            </div>
                        </>
                    )}
                </section>
            ))}
        </div>
    );
}

export default Sector;
