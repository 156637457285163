import { Auth0Provider } from "@auth0/auth0-react";
import React from "react"
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({children}) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri= process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL;
    const audience = process.env.REACT_APP_AUTH0_MANAGEMENT_AUDIENCE;

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname)
    }

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="localstorage"
            >
            {children}
        </Auth0Provider>
    )
}




