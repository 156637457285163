import './curtailmentPage.css'

import curtailmentPoster from '../../../Images/Curltailment-poster.png'
import FirnLogoHorBlack from '../../../Images/firn-hor-white 1.png';

import { GiHamburgerMenu } from "react-icons/gi";
import { VscAccount } from "react-icons/vsc";
import { useState } from 'react';

const CurtailmentPage = () => {

    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };
    
    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='homepage__curtailment__container'>
            <div className="HomePage-Navbar-curtailment">
                <div className="HomePage-Navbar-Logo"><img className="HomePage-Navbar-Logo-img" src={FirnLogoHorBlack} alt="FirnLogoWhiteHor" /></div>
                <div className="HomePage-Navbar-Item-Container-Desktop">
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/">{'Home'}</a></div>
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/curtailment">{'Curtailment'}</a></div>
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/form">{'Info sessions'}</a></div>
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/contact">{'Contact'}</a></div>
                </div>
            </div>
            <div className="HomePage-Navbar-Item-Container-Phone-curtailment">
                <div className="HomePage-Navbar-Logo"><img className="HomePage-Navbar-Logo-img" src={FirnLogoHorBlack} alt="FirnLogoWhiteHor" /></div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight:"20px"}}>
                        <GiHamburgerMenu onClick={toggleMenu} style={{width:"30px", height:"30px", verticalAlign: 'middle', color: "white"}}/>
                        {isOpen && (
                            <div
                                className="HomePage-Burger-Menu-DropDown"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="HomePage-Burger-Menu-DropDown-Item" onClick={() => {
                                    setIsOpen(false);
                                }}><a style={{color:"black"}} href="/">{'Home'}</a></div>
                                <div className="HomePage-Burger-Menu-DropDown-Item" onClick={() => {
                                    setIsOpen(false);
                                }}><a style={{color:"black"}} href="/contact">{"Contact"}</a></div>
                                <div className="HomePage-Burger-Menu-DropDown-Item" onClick={() => {
                                    setIsOpen(false);
                                }}><a style={{color:"black"}} href="/form">{'Info Sessions'}</a></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <img className='Homepage__curtailment__Poster' src={curtailmentPoster} alt="" />
        </div>
    )
}

export default CurtailmentPage