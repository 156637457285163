import './App.css';
import './translations/i18n';

import { Route, Routes } from "react-router-dom";

import {
    ContactRoute, 
    ControllerRoute, 
    CurtailmentRoute, 
    DashboardRoute, 
    FormRoute, 
    HomeRoute, 
    NewsRoute, 
    OplossingenRoute,
    BatteryTowerRoute,
    PartnersRoute,
    PowerblockRoute,
    ContainerRoute,
    SectorRoute,
    WebAdminPage,
    HomePageBodyRoute,
    RequestSimulationRoute
} from './components/pages/pages';

function App() {
    return (
        <Routes>
            <Route path="/" element={<HomeRoute />} />
            <Route path="/AdminPage" element={<WebAdminPage />} />
            <Route path="/Contact" element={<ContactRoute />} />
            <Route path='/Controller' element={<ControllerRoute />} />
            <Route path="/Curtailment" element={<CurtailmentRoute />} />
            <Route path="/Form" element={<FormRoute />} />
            <Route path="/HomePage" element={<DashboardRoute />} />
            <Route path="/Nieuws" element={<NewsRoute />} />
            <Route path='/Oplossingen' element={<OplossingenRoute />} />
            <Route path="/Industrie" element={<SectorRoute sector="industrie" />} />
            <Route path="/Horeca" element={<SectorRoute sector="horeca" />} />
            <Route path="/Residentieel" element={<SectorRoute sector="residentieel" />} />
            <Route path="/Landbouw" element={<SectorRoute sector="landbouw" />} />
            <Route path="/BatteryTower" element={<BatteryTowerRoute />} />
            <Route path="/Powerblock" element={<PowerblockRoute />} />
            <Route path="/Partners" element={<PartnersRoute />} />
            <Route path="/Container" element={<ContainerRoute />} />
            <Route path="/BodyPage/:kader" element={<HomePageBodyRoute />} />
            <Route path="/SimulatieAanvragen" element={<RequestSimulationRoute />} />
        </Routes>
    );
}

export default App;
