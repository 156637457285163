import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './requestSimulationForm.css';

const apiUrl = 'https://firn-backend-development.onrender.com/api/simulation-request';

const RequestSimulationForm = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        contactPerson: '',
        address: '',
        phone: '',
        email: '',
        annualUsage: '',
        pvProduction: '',
        injection: '',
        connectionPower: '',
        purchaseCostPerKwh: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submissionError, setSubmissionError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\+?[0-9]{10,}$/;
        return phonePattern.test(phone);
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.companyName) formErrors.companyName = 'Vereist';
        if (!formData.contactPerson) formErrors.contactPerson = 'Vereist';
        if (!formData.address) formErrors.address = 'Vereist';
        if (!formData.phone) {
            formErrors.phone = 'Vereist';
        } else if (!validatePhone(formData.phone)) {
            formErrors.phone = 'Ongeldig telefoonnummer';
        }
        if (!formData.email) {
            formErrors.email = 'Vereist';
        } else if (!validateEmail(formData.email)) {
            formErrors.email = 'Ongeldig emailadres';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            setSubmissionError('');

            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    setIsSubmitted(true);
                    console.log('Formulier succesvol verzonden!');
                    sendEmails();
                } else {
                    const errorData = await response.json();
                    setSubmissionError(errorData.error || 'Er is een fout opgetreden bij het verzenden.');
                }
            } catch (error) {
                console.error('Error bij het verzenden:', error);
                setSubmissionError('Er is een fout opgetreden bij het verzenden.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const sendEmails = () => {
        // E-mail naar bedrijf
        emailjs.send(
            // 'service_6tscwus',          // stage@firnenergy.com
            'service_hekx19p',      // it@firnenergy.com
            'request_simulation',
            formData,
            // 'VXY6bsFZJ5f63HRrv'         // stage@firnenergy.com
            'whZCVFXRoKwL6LE_B'     // it@firnenergy.com
        ).then(() => {
            console.log('Verzoek e-mail succesvol verzonden');
        }).catch((error) => {
            console.error('Error bij het verzenden van verzoek e-mail:', error);
            setSubmissionError('Fout bij het verzenden van e-mails.');
        });

        // Bevestigingsmail naar klant
        emailjs.send(
            // 'service_6tscwus',          // stage@firnenergy.com
            'service_hekx19p',        // it@firnenergy.com
            'confirmation_request',
            formData,
            // 'VXY6bsFZJ5f63HRrv'         // stage@firnenergy.com
            'whZCVFXRoKwL6LE_B'       // it@firnenergy.com
        ).then(() => {
            console.log('Bevestigingsmail verzonden');
        }).catch((error) => {
            console.error('Error bij het verzenden van bevestigingsmail:', error);
            setSubmissionError('Fout bij het verzenden van bevestigingsmail.');
        });
    };

    return (
        <div className="request-simulation-container">
            <h2>Vraag een simulatie aan</h2>
            <form onSubmit={handleSubmit} className="request-simulation-form">
                <div>
                    <label>Onderneming*</label>
                    <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} className={errors.companyName ? 'input-error' : ''} />
                    {errors.companyName && <span>{errors.companyName}</span>}
                </div>
                <div>
                    <label>Contact Persoon*</label>
                    <input type="text" name="contactPerson" value={formData.contactPerson} onChange={handleChange} className={errors.contactPerson ? 'input-error' : ''} />
                    {errors.contactPerson && <span>{errors.contactPerson}</span>}
                </div>
                <div>
                    <label>Adres*</label>
                    <input type="text" name="address" value={formData.address} onChange={handleChange} className={errors.address ? 'input-error' : ''} />
                    {errors.address && <span>{errors.address}</span>}
                </div>
                <div>
                    <label>Telefoonnummer*</label>
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} className={errors.phone ? 'input-error' : ''} />
                    {errors.phone && <span>{errors.phone}</span>}
                </div>
                <div>
                    <label>Email*</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className={errors.email ? 'input-error' : ''} />
                    {errors.email && <span>{errors.email}</span>}
                </div>
                <div>
                    <label>Jaarverbruik in kWh</label>
                    <input type="number" name="annualUsage" value={formData.annualUsage} onChange={handleChange} />
                </div>
                <div>
                    <label>Productie PV in kWh</label>
                    <input type="number" name="pvProduction" value={formData.pvProduction} onChange={handleChange} />
                </div>
                <div>
                    <label>Injectie in kWh</label>
                    <input type="number" name="injection" value={formData.injection} onChange={handleChange} />
                </div>
                <div>
                    <label>Aansluitvermogen installatie (kVA)</label>
                    <input type="number" name="connectionPower" value={formData.connectionPower} onChange={handleChange} />
                </div>
                <div>
                    <label>Aankoop totaalkost per kWh</label>
                    <input type="number" step="0.01" name="purchaseCostPerKwh" value={formData.purchaseCostPerKwh} onChange={handleChange} />
                </div>
                <button type="submit" disabled={isLoading || isSubmitted}>
                    {isLoading ? "Loading..." : isSubmitted ? "Verzonden" : "Verstuur aanvraag"}
                </button>
                {submissionError && <p className="error-message">{submissionError}</p>}
            </form>
        </div>
    );
};

export default RequestSimulationForm;
