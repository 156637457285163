import { useContext, useEffect, useState } from 'react';
import './graphSelectorItem.css'
import {ComposedChart, ResponsiveContainer, Line, Area} from 'recharts';
import { DarkModeContext } from '../../../../../context/DarkModeContext';
import { Spin } from 'antd'

const GraphSelectorItem = ({Name, Color, Data, GradientId, onDragStart, onDragEnd, Loader}) => {

    const {darkMode} = useContext(DarkModeContext);
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0});
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(Data)
    
    useEffect(() => {
        
        if (Data.length != 0) {
            setLoading(false)
        } else {
            const timer = setTimeout(() => {
                setLoading(false)
                setData([])
            }, 5000)

            return () => clearTimeout(timer)
        }
        setData(Data);
    }, [Data])

    const handleMouseDown = (e) => {
        setIsDragging(true);
        const offsetX = e.clientX - e.target.getBoundingClientRect().left;
        const offsetY = e.clientY - e.target.getBoundingClientRect().top;
        setDragOffset({x: offsetX, y: offsetY});
        onDragStart && onDragStart();
    }

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const containerRect = document.querySelector(".GraphDisplay").getBoundingClientRect();
        const newX = e.clientX - containerRect.left - dragOffset.x;
        const newY = e.clientY - containerRect.top - dragOffset.y;
        e.target.style.left = `${newX}px`;
        e.target.style.top = `${newY}px`;
    }

    const handleMouseUp = () => {
        setIsDragging(false);
        onDragEnd && onDragEnd();
    }

    return (
        <div className={`GraphSelector-Item-Container ${darkMode ? 'darkmode' : 'lightmode'}`}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}      
            style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px'}}
        >
            <div className='GraphSelector-Item-Title'>{Name}</div>
            {loading || Loader ? (
                <div style={{textAlign: 'center', marginTop: 20}}>
                    <Spin size="large" />
                </div>
            ) : data.length > 0 ? ( 
                <div className='GraphSelector-Item-Graph'>
                    
                    <ResponsiveContainer width={'100%'} height={'105%'}>
                        <ComposedChart style={{cursor:'pointer'}} data={data}>
                            <defs>
                                <linearGradient id={GradientId} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={Color} stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor={Color} stopOpacity={0.1}/>
                                </linearGradient>
                            </defs>

                            <Area type={'monotone'} dataKey={'value'} stroke={Color} fillOpacity={1} strokeWidth={2} fill={`url(#${GradientId})`} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            )
            : <div style={{marginLeft: 20, marginTop: 20}}>No data found</div>}
        </div>
    )

}

export default GraphSelectorItem