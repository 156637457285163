import axios from 'axios';

const baseURL = process.env.REACT_APP_DATA_SERVICE_URL + "/api/controller/settings";

const Settings = () => {

    const getRelais = async (serialNr) => { 

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: baseURL + '/' + serialNr,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            params: {
                type: 'relais'
            }
        };
        
        try {
            const response = await axios.get(config.url, config);
            return response.data; // Return the data after successful response
        } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to handle it in HandleLoginEvent if needed
        }
    }

    const addRelais = async (serialNr, data) => { 

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        const relaisData = JSON.stringify({
            name: data.name,
            node_id: data.nodeId,
            gateway: data.gateway,
            mode: '',

        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseURL + '/' + serialNr + '/relais',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            data: relaisData
        };

        try {
            const response = await axios.post(config.url, config.data, config);
            return response.data; // Return the data after successful response
        } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to handle it in HandleLoginEvent if needed
        }
    }

    const updateRelais = async (serialNr, relais) => { 

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseURL + '/' + serialNr + '/relais',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            data: relais
        };

        try {
            const response = await axios.post(config.url, config.data, config);
            return response.data; // Return the data after successful response
        } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to handle it in HandleLoginEvent if needed
        }
    }

    const getClientDetails = async (serialNr) => { 

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: baseURL + '/' + serialNr,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            params: {

            }
        };
        
        try {
            const response = await axios.get(config.url, config);
            return response.data; // Return the data after successful response
        } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to handle it in HandleLoginEvent if needed
        }
    }


    return {
        getRelais,
        addRelais,
        updateRelais,
        getClientDetails
    }

}

export default Settings;