import axios from 'axios';

const baseURL = process.env.REACT_APP_DATA_SERVICE_URL + "/api/strategy/calendar";

const Calendar = () => {

    const getCalendarData = async (month, year, serialNr) => { 

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: baseURL + `/get_data/${serialNr}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            params: {
                month: month,
                year: year
            }
        };

        try {
            const response = await axios.get(config.url, config);
            return response.data; // Return the data after successful response
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const updateCalendarData = async (dates, serialNr) => {

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        const data = JSON.stringify({
            "calendar_dates": dates,
            "client_id": serialNr
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseURL + `/update_data`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
        };

        try {
            const response = await axios.post(config.url, data, config);
            return response.data; // Return the data after successful response
        } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to handle it in HandleLoginEvent if needed
        }
    }


    return {
        getCalendarData,
        updateCalendarData,
    }

}

export default Calendar;