import React, { useState, useEffect } from 'react';
import './simulationRequestsList.css';
import { formatNumber } from '../offerte/utils/helpers';

const apiUrl = 'https://firn-backend-development.onrender.com/api/simulation-request';

const SimulationRequestsList = () => {
    const [simulationRequests, setSimulationRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedRequestId, setExpandedRequestId] = useState(null);

    useEffect(() => {
        // Ophalen van alle simulatieaanvragen
        const fetchSimulationRequests = async () => {
            try {
                const response = await fetch(apiUrl);
                if (response.ok) {
                    const data = await response.json();
                    setSimulationRequests(data);
                } else {
                    setError('Fout bij het ophalen van de simulatieaanvragen.');
                }
            } catch (error) {
                setError('Er is een fout opgetreden bij het ophalen van de gegevens.');
            } finally {
                setLoading(false);
            }
        };

        fetchSimulationRequests();
    }, []);

    const handleToggleDetails = (requestId) => {
        setExpandedRequestId(expandedRequestId === requestId ? null : requestId);
    };

    return (
        <div className="simulation-requests-list-container">
            <h2>Simulatie aanvraag formulieren:</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <table className="simulation-requests-table">
                    <thead>
                        <tr>
                            <th>Onderneming</th>
                            <th>Email</th>
                            <th>Jaarverbruik</th>
                            <th>Productie PV</th>
                            <th>Injectie</th>
                            <th>Aansluitvermogen</th>
                            <th>Aankoop totaalkost</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {simulationRequests.map((request) => (
                            <React.Fragment key={request.id}>
                                <tr>
                                    <td>{request.companyName}</td>
                                    <td>{request.email}</td>
                                    <td>{formatNumber(request.annualUsage)} kWh</td>
                                    <td>{formatNumber(request.pvProduction)} kWh</td>
                                    <td>{formatNumber(request.injection)} kWh</td>
                                    <td>{formatNumber(request.connectionPower)} kVA</td>
                                    <td>{formatNumber(request.purchaseCostPerKwh)} €/kWh</td>
                                    <td>
                                        {/* Details knop */}
                                        <button onClick={() => handleToggleDetails(request.id)}>
                                            Details
                                        </button>
                                    </td>
                                </tr>
                                {expandedRequestId === request.id && (
                                    <tr className="details-row">
                                        <td colSpan="11">
                                            <div className="request-details">
                                                <p><strong>Contact Persoon:</strong> {request.contactPerson}</p>
                                                <p><strong>Adres:</strong> {request.address}</p>
                                                <p><strong>Telefoon:</strong> {request.phone}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SimulationRequestsList;
