import React, { useEffect, useRef, useState } from "react";
import "./homePageHeaderBody.css";

const HomePageHeaderBody = () => {

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth - 20)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [highlightedBox, setHighlightedBox] = useState(-1);
    const [svgPosition, setSvgPosition] = useState({ x: 0, y: 0 })
    const [ballPosition, setBallPosition] = useState({ x: 0, y: 10 })

    const [responsive, setResponsive] = useState(window.innerWidth < 1300);

    const pathRef = useRef(null);
    const svgRef = useRef(null);

    const marginBetweenBoxes = 100;
    const marginBeteenLineAndBox = 150;
    const textInset = { x: 50, y: 30 };
    const [boxSize, setBoxSize] = useState({ w: 500, h: 375 });
    const proximityRadius = 175;

    const [boxes] = useState([
        {
            title: 'Heb je een hoog verbruik?',
            description: 'Dan kunnen je energiekosten flink oplopen door de verdeling tussen energie, transport en heffingen. Ongeveer de helft van je rekening gaat naar de energie zelf. Met een batterijoplossing kun je een deel van je zonne-energie opslaan en later gebruiken, waardoor je minder energie hoeft aan te kopen.'
        },
        {
            title: 'Heb je een slimme aankoop strategie?',
            description: 'Bij ons profiteer je van dynamische energieprijzen door direct gebruik te maken van de Belpex-prijzen. Wij bieden een formule waarbij je kunt profiteren van de dagprijzen en de voordelen van negatieve prijzen. Wij zorgen ervoor dat je altijd optimaal profiteert van de meest gunstige tarieven'
        },
        {
            title: 'Profiteer nu van de onbalans',
            description: 'Elia houdt het netwerk in balans en publiceert elk kwartier de onbalansprijzen. Door hiervan gebruik te maken, kun je profiteren van prijsvariaties en mogelijk tussen de 40 en 60 €/kWh per jaar besparen op je batterij, afhankelijk van je systeeminstellingen.'
        },
        {
            title: 'Injecteer op het juiste moment',
            description: 'Bij pieken in de PV-markt kunnen negatieve prijzen optreden door een overschot aan energie. Onze curtailment-strategie verhoogt je verbruik, slaat energie op in batterijen, en schakelt indien nodig externe PV-inverters uit. Zo benut je je energieproductie optimaal en voorkom je boetes door negatieve prijzen.'
        },
        {
            title: 'Gebruik je laadpaal op het juiste moment',
            description: 'Onze geavanceerde AI-controller optimaliseert automatisch je laadpaal op basis van diverse factoren zoals weersomstandigheden en energietarieven. Hierdoor wordt je voertuig efficiënt opgeladen wanneer de kosten laag zijn en de energie beschikbaar is, zonder dat je handmatig laadschema’s hoeft in te stellen.'
        },
        {
            title: 'Kan ik gratis warm water hebben?',
            description: 'Door gebruik te maken van zonneboilers of andere duurzame technologieën, kun je je eigen warm water opwekken met behulp van gratis zonne-energie. Hierdoor verlaag je je afhankelijkheid van externe energiebronnen en profiteer je van kostenbesparingen op je energieverbruik.'
        },
        {
            title: 'Onze partners',
            description: 'Wij werken samen met verschillende partners om onze klanten de beste service te bieden. Onze partners zijn geselecteerd op basis van hun ervaring en expertise in de sector. Samen met hen zorgen wij ervoor dat onze klanten optimaal profiteren van onze diensten.',
            link: "/Partners"
        }
    ])

    useEffect(() => {
        const handleResize = () => {

            setViewportWidth(window.innerWidth - 20)
            if (window.innerWidth < 1300) {
                setResponsive(true)
            } else {
                setResponsive(false)
            }
        }

        const handleWheel = () => {
            setScrollPosition(window.scrollY - 300)
        }

        if (svgRef.current) {
            const rect = svgRef.current.getBoundingClientRect();
            setSvgPosition({ x: rect.left, y: rect.top });
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleWheel)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener('scroll', handleWheel)
        }
    }, [svgRef])

    const getDistance = (x1, y1, x2, y2) => {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
    }

    useEffect(() => {
        if (!responsive) {
            const path = pathRef.current;
            if (path) {
                const totalLength = path.getTotalLength();
                const scrollFactor = totalLength / svgRef.current.height.baseVal.value;
                const lengthAtScroll = scrollPosition * scrollFactor;

                const point = path.getPointAtLength(lengthAtScroll);
                setBallPosition({ x: point.x, y: point.y < 10 ? 10 : point.y > svgRef.current.height.baseVal.value - 10 ? null : point.y })

                boxes.forEach((box, i) => {
                    const yPos = marginBetweenBoxes + boxSize.h * 2 / 4 + (boxSize.h * 3 / 4) * i
                    const xPos = i % 2 == 0 ? viewportWidth / 2 - marginBeteenLineAndBox : viewportWidth / 2 + marginBeteenLineAndBox

                    const distance = getDistance(xPos, yPos, point.x, point.y)

                    if (distance < proximityRadius) {
                        setHighlightedBox(i);
                    } else if (highlightedBox === i) {
                        setHighlightedBox(-1);
                    }
                })
            }
        }
    }, [scrollPosition])

    return (
        <>
            {!responsive ? (
                <svg ref={svgRef} height={boxes.length * (boxSize.h * 3 / 4) + marginBetweenBoxes + 100} width={viewportWidth} className="homepage__headerbody__svg">
                    <path
                        ref={pathRef}
                        d={`M${viewportWidth / 2} 0 ${boxes.map((box, i) => {
                            const yPos = marginBetweenBoxes + (boxSize.h * 3 / 4) * (i + 1) - boxSize.h * 1 / 4;
                            const xPos = i % 2 === 0 ? viewportWidth / 2 - marginBeteenLineAndBox : viewportWidth / 2 + marginBeteenLineAndBox;

                            return `L${viewportWidth / 2} ${yPos} L${xPos} ${yPos} L${viewportWidth / 2} ${yPos}`;
                        }).join(' ')} L${viewportWidth / 2} ${boxes.length * (boxSize.h * 3 / 4) + marginBetweenBoxes + 100}`}
                        className="homepage__headerbody__path"
                    />

                    <circle cx={ballPosition.x} cy={ballPosition.y} r={10} className="homepage__headerbody__circle" />

                    {boxes.map((box, i) => (
                        <React.Fragment key={i}>
                            <rect
                                x={i % 2 !== 0 ? viewportWidth / 2 + marginBeteenLineAndBox : viewportWidth / 2 - marginBeteenLineAndBox - boxSize.w}
                                y={marginBetweenBoxes + (boxSize.h * 3 / 4) * i}
                                width={boxSize.w}
                                height={boxSize.h}
                                className={`homepage__headerbody__rect ${highlightedBox === i ? 'highlighted' : ''}`}
                            />
                            <foreignObject
                                x={i % 2 !== 0 ? viewportWidth / 2 + marginBeteenLineAndBox + textInset.x : viewportWidth / 2 - boxSize.w - marginBeteenLineAndBox + textInset.x}
                                y={marginBetweenBoxes + textInset.y + (boxSize.h * 3 / 4) * i}
                                width={boxSize.w - textInset.x - 20}
                                height={boxSize.h - textInset.y}
                            >
                                <div className={`homepage__headerbody__title ${highlightedBox === i ? 'highlighted' : ''}`}>
                                    {box.title}
                                </div>
                            </foreignObject>
                            <foreignObject
                                x={i % 2 !== 0 ? viewportWidth / 2 + marginBeteenLineAndBox + textInset.x : viewportWidth / 2 - boxSize.w - marginBeteenLineAndBox + textInset.x}
                                y={marginBetweenBoxes + textInset.y + (boxSize.h * 3 / 4) * i + 100}
                                width={boxSize.w - textInset.x - 20}
                                height={boxSize.h - textInset.y}
                            >
                                <div className={`homepage__headerbody__description ${highlightedBox === i ? 'highlighted' : ''}`}>
                                    {box.description}
                                </div>
                                <div className={`homepage__headerbody__link ${highlightedBox === i ? 'highlighted' : ''}`}>
                                    <a href={box.link || `BodyPage/${i + 1}`}>Lees hier meer <i className='fas fa-arrow-right' /></a>
                                </div>
                            </foreignObject>
                        </React.Fragment>
                    ))}
                </svg>
            ) : (
                <div className="homepage__headerbody__responsive-container">
                    {boxes.map((box, i) => (
                        <div className="homepage__headerbody__responsive-box" key={i}>
                            <div className="homepage__headerbody__title">{box.title}</div>
                            <div className="homepage__headerbody__description">{box.description}</div>
                            <div className="homepage__headerbody__link"><a href={box.link || `BodyPage/${i + 1}`}>Lees hier meer <i className='fas fa-arrow-right' /></a></div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default HomePageHeaderBody;
