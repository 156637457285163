import React, { useContext, useEffect, useState } from "react";
import User from "../../../api/user";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import axios from "axios";

const InstallationList = ({ role, user }) => {
    const { login } = useContext(UserContext);
    const userApi = User();

    const [installations, setInstallations] = useState([]);
    const [installerView, setInstallerView] = useState(null);
    const [sortedInstallations, setSortedInstallations] = useState(null);
    const [collapsedRoles, setCollapsedRoles] = useState({});

    const navigate = useNavigate();
    const [superRole, setSuperRole] = useState("");

    const fetchInstallations = async (role, installerMock) => {
        let installationsresponse;
        setSuperRole(role)
        if (role === "Installer") {
            const domain = process.env.REACT_APP_AUTH0_DOMAIN;
            const token = await userApi.getManagementApiToken();

            let metaData = "";
            if (!installerMock) {
                const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
                const metadataResponse = await axios.get(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                metaData = metadataResponse.data.app_metadata;
            } else {
                metaData = installerMock.app_metadata;
            }

            installationsresponse = await userApi.getUsersBySerialNr(metaData.clients);

            if (metaData.serial_Nr) {
                const filteredInstallations = installationsresponse.filter(installation => {
                    if (installation.app_metadata.serial_Nr === metaData.serial_Nr) {
                        installation.app_metadata.role = "User";
                    }

                    return installation;
                });
                setInstallations(filteredInstallations);
            } else {
                setInstallations(installationsresponse);
            }
        } else if (role === "Admin" || role === "SuperAdmin" || role === "Sales") {
            try {
                const userResponse = await userApi.getAllUsersInDatabase();
                installationsresponse = userResponse;

                setInstallations(installationsresponse);
            } catch (error) {
                console.log(error);
                setInstallations([]);
            }
        }
    };

    useEffect(() => {
        sortInstallationsIntoLists()
    }, [installations])

    const sortInstallationsIntoLists = () => {
        const tempSortedList = {}

        const sortedByEmail = installations.sort((a, b) => {
            // Convert emails to lowercase to ensure case-insensitive sorting
            const emailA = a.email.toLowerCase();
            const emailB = b.email.toLowerCase();

            if (emailA < emailB) {
                return -1; // a comes before b
            }
            if (emailA > emailB) {
                return 1; // a comes after b
            }
            return 0; // a and b are equal
        });

        try {
            for (let i = 0; i < sortedByEmail.length; i++) {
                const installation = sortedByEmail[i];

                if (installation.app_metadata && installation.app_metadata.role) {
                    if (!tempSortedList[`${installation.app_metadata.role}`]) {
                        tempSortedList[`${installation.app_metadata.role}`] = [installation]
                    } else {
                        tempSortedList[`${installation.app_metadata.role}`].push(installation)
                    }
                } else {
                    if (!tempSortedList[`Other`]) {
                        tempSortedList[`Other`] = [installation]
                    } else {
                        tempSortedList[`Other`].push(installation)
                    }
                }
            }
            setSortedInstallations(tempSortedList)

            const initialCollapsedState = {};
            Object.keys(tempSortedList).forEach(role => {
                if (role === 'User') {
                    initialCollapsedState[role] = false;
                } else {
                    initialCollapsedState[role] = true;
                }
            });
            setCollapsedRoles(initialCollapsedState);
        } catch (e) {
            setSortedInstallations(null)
        }

    }

    useEffect(() => {
        fetchInstallations(role, null);
    }, [role]);

    const toggleRoleCollapse = (role) => {
        setCollapsedRoles(prevState => ({
            ...prevState,
            [role]: !prevState[role]
        }));
    }

    const returnToAdminDashboard = () => {
        setInstallerView(null);
        fetchInstallations(role, null);
    };

    const navigateToUserDashboard = (user) => {
        if (user) {
            login(user);
            navigate("/HomePage");
        } else {
            console.log("Something went wrong. Try again later.");
        }
    };

    const navigateToInstallerDashboard = (installer) => {
        if (installer) {
            setInstallerView(installer);
            fetchInstallations("Installer", installer);
        } else {
            console.log("Something went wrong. Try again later.");
        }
    };

    return (
        <div>
            {installerView && (
                <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                    <div>Current view of installer: {installerView.email}</div>
                    <div>
                        <button className="adminDashboard__table__btn adminDashboard__table__installerdashboard__btn" onClick={returnToAdminDashboard}>
                            Return to Admin View
                        </button>
                    </div>
                </div>
            )}

            {sortedInstallations && Object.keys(sortedInstallations).map(role => (
                <div key={role} className="collapsible-section">
                    <div className="collapsible-header" onClick={() => toggleRoleCollapse(role)}>
                        <h3>{role} ({sortedInstallations[role].length})</h3>
                        <span className={`triangle ${collapsedRoles[role] ? 'collapsed' : ''}`}></span>
                    </div>


                    {!collapsedRoles[role] && (
                        <table className="adminDashboard__table-user">
                            <thead>
                                <tr>
                                    <th>Picture</th>
                                    <th>Email</th>
                                    <th>Serial number</th>
                                    <th>Alias</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedInstallations[role].map((installation, index) => (
                                    <tr key={index}>
                                        <td><img src={installation.picture} width={30} style={{ borderRadius: 10 }} /></td>
                                        <td>{installation.email}</td>
                                        <td>{installation.app_metadata?.serial_Nr || "Unkown"}</td>
                                        <td>{installation.app_metadata?.projectId || "Unkown"}</td>
                                        {installation.app_metadata?.role === 'User' ? (
                                            <td>
                                                <button onClick={() => navigateToUserDashboard(installation)} className="adminDashboard__table__btn adminDashboard__table__userdashboard__btn">Go to User Dashboard</button>
                                                {!installation.app_metadata.serial_Nr.includes("OM") && (superRole.toLowerCase() === "superadmin" || superRole.toLowerCase() === "sales" || superRole.toLowerCase() === "admin") && <button className="adminDashboard__table__btn adminDashboard__table__installerdashboard__btn" onClick={() => window.location.href = "https://firnenergy.grafana.net/login"}>Go to grafana dashboard</button>}
                                            </td>

                                        ) : installation.app_metadata?.clients && installation.app_metadata?.role === "Installer" ? (
                                            <td><button onClick={() => navigateToInstallerDashboard(installation)} className="adminDashboard__table__btn adminDashboard__table__installerdashboard__btn">Go to Installer Dashboard</button></td>

                                        ) : (
                                            <td><button className="adminDashboard__table__btn adminDashboard__table__button-disabled" disabled>No Action Available</button></td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            ))}

            {!sortedInstallations &&
                <div className='adminDashboard__error__not__found--lightmode'>
                    <div style={{ textAlign: 'center', color: '#c62828', gridColumn: "span 5" }}>None found</div>
                </div>
            }
        </div>
    );
};

export default InstallationList;
